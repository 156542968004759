import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import { Page, View, Text, analytics, Input, Button } from 'lib'
import theme from '_appSetup/Theme'
import { Grid, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox } from '@material-ui/core'
import firebase from 'gatsby-plugin-firebase'
import { fetchUserData } from 'actions/User'
import WAValidator from 'multicoin-address-validator'
import { TwoFADialog } from '../profile/_dialogs'
import ACHDetails from '../deposit/ach/_achDetails'
import moment from 'moment'

import { useUserData, formatUSD } from '../dashboard/_utils'
import WireDetails from '../deposit/wire/_wireDetails'

import '_appSetup/Global.css'

const MINIMUM_TRANSACTION_AMOUNT = 0.5

const getRequestStatusText = (status) => {
  const options = {
    SUCCESS: { title: 'Withdrawal request submitted', text: `Your request has been submitted. `, button: 'Sent!' },
    PENDING: { title: 'Sending your request...', button: 'Please wait' },
    ERROR: { title: 'Error', text: `We encountered an error. Please refresh the page and try again. If the error persists, please contact our support.`, button: 'Error' },
    NONE: { title: 'Confirm withdrawal', button: 'Confirm withdrawal' },
  }
  return options[status]
}

const calculateAvailableToWithdraw = ({ UserData }) => {
  return { availableToWithdraw: UserData?.balance || 0, onHold: 0 }
}

const TransferDetails = (props) => {
  const styles = useStyles(props)
  const [amount, setAmount] = useState('')
  const [withdrawalAddress, setWithdrawalAddress] = useState('')

  const [USDCEmergencyConsent, setUSDCEmergencyConsent] = useState(false)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [twoFADialogOpen, setTwoFADialogOpen] = useState(false)
  const [withdrawalRequestStatus, setWithdrawalRequestStatus] = useState('NONE')

  const [valid, setValid] = useState({
    amount: true,
    withdrawalAddress: true,
  })

  const { UserData } = useUserData()

  const isWire = props.payoutMethod == 'wire'
  const isUSDC = props.payoutMethod == 'USDC'
  const isACH = props.payoutMethod == 'ach'

  const { availableToWithdraw, onHold } = calculateAvailableToWithdraw({ UserData, payoutMethod: props.payoutMethod })

  useEffect(() => {
    setAmount(formatUSD(availableToWithdraw, true))
  }, [availableToWithdraw])

  useEffect(() => {
    const lastWithdrawalAddress = UserData?.lastWithdrawalAddressUsed
    if (lastWithdrawalAddress && WAValidator.validate(lastWithdrawalAddress, 'ETH')) {
      setWithdrawalAddress(lastWithdrawalAddress)
    }
  }, [UserData?.lastWithdrawalAddressUsed])

  const validate = (name, value) => {
    switch (name) {
      case 'amount':
        setValid({ ...valid, amount: value > 0 && value <= availableToWithdraw && value >= MINIMUM_TRANSACTION_AMOUNT })
        break
      case 'withdrawalAddress':
        setValid({ ...valid, withdrawalAddress: WAValidator.validate(value, 'ETH') })
        break
      default:
        break
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    const processedValue = value

    switch (name) {
      case 'amount':
        setAmount(processedValue)
        break
      case 'withdrawalAddress':
        setWithdrawalAddress(processedValue)
        break
    }

    validate(name, processedValue)
  }

  const formIsValid = valid.amount &&
    amount > 0 && (
    !isUSDC || (
      valid.withdrawalAddress &&
      withdrawalAddress.length > 0
    ))

  const sendRequest = (attemptId) => {
    setTwoFADialogOpen(false)
    setWithdrawalRequestStatus('PENDING')
    const withdrawalFunc = firebase.functions().httpsCallable('requestWithdrawalUSDCEmergency')

    const handleWithdrawalError = (e) => {
      setWithdrawalRequestStatus('ERROR')
    }

    const withdrawalArgs = { amount, withdrawalAddress, payoutMethod: props.payoutMethod, attemptId }
    if (isUSDC) withdrawalArgs.withdrawalAddress = withdrawalAddress

    withdrawalFunc(withdrawalArgs)
      .then(result => {
        if (result.data?.result == 'SUCCESS') {
          // success
          setWithdrawalRequestStatus('SUCCESS')
          fetchUserData({})
        } else {
          handleWithdrawalError()
        }
      }).catch(handleWithdrawalError)
  }

  const verify2FAIfNeeded = () => {
    setTwoFADialogOpen(true)
  }

  const modalText = {
    text: `Are you sure that you would like to withdraw ${formatUSD(amount)} UST in USDC at the market rate and that ${withdrawalAddress} is the correct USDC withdrawal address?`,
    ...getRequestStatusText(withdrawalRequestStatus),
  }

  const withdrawalFee = {
    USDC: 'Fee: $0',
  }

  let helperText = ''

  if (amount > 0 && amount < MINIMUM_TRANSACTION_AMOUNT) {
    helperText = `The minimum amount you can withdraw is ${formatUSD(MINIMUM_TRANSACTION_AMOUNT)} UST`
  } else {
    helperText += `Available to withdraw: ${formatUSD(availableToWithdraw, true)} UST. `
    if (onHold > 0) helperText += `On hold: ${formatUSD(onHold)}. `
    helperText += `${withdrawalFee[props.payoutMethod]}.`
  }

  return (
    <View style={styles.topWrapper}>


      <Grid container spacing={2} justify='flex-start'>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Input
            value={amount}
            onChange={handleChange}
            name='amount'
            size='small'
            type='number'
            label={'UST amount to withdraw as USDC'}
            InputProps={{
              startAdornment: <InputAdornment position='start'>UST</InputAdornment>,
              onBlur: () => handleChange({ target: { name: 'amount', value: Math.round(amount * 100) / 100 } }),
            }}
            required
            error={!valid.amount}
            fullWidth
            disabled
            helperText={helperText}
          />
        </Grid>

        {isUSDC && (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              value={withdrawalAddress}
              onChange={handleChange}
              name='withdrawalAddress'
              autoFocus
              size='small'
              label={`Your USDC address`}
              required
              error={!valid.withdrawalAddress}
              fullWidth
              helperText={`We'll send the USDC to this Ethereum/ERC20 network address.`}
            />
          </Grid>
        )}
      </Grid>


      {UserData.EMERGENCY_MODE && (
        <Grid item xs={12} className={styles.spaced}>
          <Text>Requesting a USDC withdrawal during the ongoing market volatility means that you are instructing us to:</Text>
          <ol>
            <Text><li>Convert your UST to USDC at the market rate available to Stablegains at the time of processing the conversion. Please note, the rate of conversion can be different from the rate at the time of your request.</li></Text>
            <Text><li>Send the resulting amount of USDC to the Ethereum/ERC20 address you have provided.</li></Text>
          </ol>

          <Text variant='subtitle2' style={`${styles.opaque} ${styles.spaceTop}`}>Stablegains will process your withdrawal under the following terms:</Text>
          <ul>
            <Text variant='subtitle2' style={styles.opaque}><li>Between the time you submit your request and we execute the conversion, the UST price can change drastically. For instance, if the exchange rate happens to be 0.15, for each 1000 UST withdrawn you'll receive 150 USDC. You agree to accept the market price at the time of the execution.</li></Text>
            <Text variant='subtitle2' style={styles.opaque}><li>Under no circumstances shall Stablegains be liable to losses due to the exchange rate of UST to USDC at the time of processing your USDC withdrawal request. </li></Text>
          </ul>

          <View style={styles.checkboxLabelWrapper}>
            <Checkbox
              checked={USDCEmergencyConsent}
              onChange={(e) => setUSDCEmergencyConsent(e.target.checked)}
              name='emergencyConsent'
              color='primary'
            />
            <Text style={styles.acceptTermsText}>* I have read and agree to the terms outlined above.</Text>
          </View>

        </Grid>
      )}

      <Button
        disabled={!formIsValid || !USDCEmergencyConsent}
        style={styles.submitButton}
        variant='contained'
        size='large'
        color='secondary'
        text={'Request withdrawal'}
        onClick={() => {
          analytics.track('Withdrawal Request', {
            method: isWire ? 'wire' : isUSDC ? 'USDC' : 'ACH',
          })
          setConfirmationDialogOpen(true)
        } }/>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {modalText.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {modalText.text}

            <br/> <br/>Please note: Due to the current UST volatility, while UST is off its $1.00 peg, you will receive fewer USD/USDC. For instance, if the UST rate is $0.15 at the time of conversion, you will receive 150 USD/USDC for every 1000 UST you request to withdraw.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={verify2FAIfNeeded} variant='contained' color='primary' disabled={withdrawalRequestStatus !== 'NONE'}>
            {modalText.button}
          </Button>
          <Button onClick={() => setConfirmationDialogOpen(false)} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <TwoFADialog
        open={twoFADialogOpen}
        closeDialog={() => setTwoFADialogOpen(false)}
        onConfirm={(attemptId) => sendRequest(attemptId)}
        requireEmailAuthentication
      />
    </View>
  )
}


const useStyles = makeStyles({
  topWrapper: {
    marginTop: theme.spacing(4),
  },
  leftWrapper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  depositInfoWrapper: {
    paddingBottom: theme.spacing(4),
    alignSelf: 'stretch',
    wordBreak: 'break-word',
  },
  head: {
    marginBottom: theme.spacing(1),
  },
  subheader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    opacity: 0.5,
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    borderTop: '1px solid #00000016',
    paddingTop: theme.spacing(4),
  },
  spaced: {
    marginTop: theme.spacing(4),
  },
  submitButton: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  textHeader: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  checkboxLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  confirm: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  wireInstructionsWrapper: {
    marginBottom: theme.spacing(4),
  },
  opaque: {
    opacity: 0.5,
  },
  spaceTop: {
    marginTop: theme.spacing(4),
  },
})

export default TransferDetails
