import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import { Page, View, Text, WhiteGreyLayout, SEO, Table } from 'lib'
import theme from '_appSetup/Theme'
import { Tabs, Tab } from '@material-ui/core'
import moment from 'moment'
import firebase from 'gatsby-plugin-firebase'

import { useUserData, formatUSD } from '../dashboard/_utils'

import '_appSetup/Global.css'

import WithdrawalForm from './_withdrawalForm'
import WithdrawalFormBankEmergency from './_withdrawalFormBankEmergency'
import WithdrawalFormUST from './_withdrawalFormUST'
import WithdrawalFormUSDCEmergency from './_withdrawalFormUSDCEmergency'
import BankAccountLinkForm from '../deposit/wire/_bankAccountLinkForm'
import ACHDetails from '../deposit/ach/_achDetails'

const WireInstructions = (props) => {
  const styles = useStyles(props)

  const { UserData } = useUserData()
  const RelevantWithdrawalForm = UserData?.EMERGENCY_MODE ? WithdrawalFormBankEmergency : WithdrawalForm

  const linkedBankAccount = UserData.linkedBankAccount

  if (linkedBankAccount) return <RelevantWithdrawalForm payoutMethod='wire'/>

  return <BankAccountLinkForm/>
}

const ACHInstructions = (props) => {
  const styles = useStyles(props)

  const { UserData } = useUserData()
  const RelevantWithdrawalForm = UserData?.EMERGENCY_MODE ? WithdrawalFormBankEmergency : WithdrawalForm

  const linkedPlaidACHAccount = UserData.linkedPlaidACHAccount

  if (linkedPlaidACHAccount) return <RelevantWithdrawalForm payoutMethod='ach'/>

  return <ACHDetails/>
}

const WithdrawPage = (props) => {
  const TAB_LABELS = {
    ACH: 'ACH',
    WIRE: 'Wire',
    USDC: 'USDC',
    UST: 'UST',
  }

  const styles = useStyles(props)
  const [selectedTab, setSelectedTab] = useState(0)
  const [availableWithdrawalMethods, setAvailableWithdrawalMethods] = useState(Object.values(TAB_LABELS))
  const { UserData } = useUserData()

  const updateAvailableWithdrawalMethods = async () => {
    const getAvailableWithdrawalMethodsFunc = firebase.functions().httpsCallable('getAvailableWithdrawalMethods')
    const availableMethods = await getAvailableWithdrawalMethodsFunc()

    const available = []

    availableMethods.data.forEach(d => available.push(TAB_LABELS[d]))

    setAvailableWithdrawalMethods(available)
  }

  useEffect(() => {
    updateAvailableWithdrawalMethods()
  }, [])

  // const tabNames = [TAB_LABELS.UST, TAB_LABELS.USDC, TAB_LABELS.WIRE, TAB_LABELS.ACH]
  const tabNames = [TAB_LABELS.UST, TAB_LABELS.USDC]

  const RelevantWithdrawalForm = UserData?.EMERGENCY_MODE ? WithdrawalFormBankEmergency : WithdrawalForm

  const LeftComponent = () => {
    return (
      <View style={styles.leftWrapper}>

        <View style={styles.depositInfoWrapper}>

          <Text variant='h5' gutterBottom style={styles.header}>Choose your withdrawal method:</Text>

          {UserData?.EMERGENCY_MODE && (
            <>
              <Text gutterBottom style={styles.textHeader}>UST is trading significantly below its target $1.00 peg and it's unclear when or if it will return to dollar parity. Read more about the situation <a href='https://stablegains.zendesk.com/hc/en-us/articles/6104159088785-UST-Depeg-What-s-happening-right-now-and-what-are-the-options' target='_blank'>in our learning center</a>.</Text>
              <Text gutterBottom style={styles.textHeader}>Pursuant to our terms, we have determined to grant permission for withdrawal requests for the market value of your UST in USDC, or for UST directly (<a href='https://stablegains.zendesk.com/hc/en-us/articles/6034101731089-How-to-withdraw-UST' target='_blank'>read more here</a>).</Text>
              <Text gutterBottom style={styles.textHeaderSpaced}>USDC can be converted easily to USD and withdrawn to a bank account via exchanges such as Coinbase. UST exchange support is more limited.</Text>
            </>

          )}

          <Tabs
            value={selectedTab}
            onChange={(e, v) => setSelectedTab(v)}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
          >
            {tabNames.map((p, i) => {
              const label = availableWithdrawalMethods.includes(p) ? p : `${p} (suspended)`
              return (
                <Tab key={i} disableRipple label={label} disabled={!availableWithdrawalMethods.includes(p)}/>
              )
            })}
          </Tabs>

          {tabNames[selectedTab] == TAB_LABELS.ACH ? <ACHInstructions/> :
            tabNames[selectedTab] == TAB_LABELS.WIRE ? <WireInstructions/> :
              (tabNames[selectedTab] == TAB_LABELS.USDC && UserData?.EMERGENCY_MODE) ? <WithdrawalFormUSDCEmergency payoutMethod='USDC'/> :
                tabNames[selectedTab] == TAB_LABELS.USDC ? <RelevantWithdrawalForm payoutMethod='USDC'/> :
                  <WithdrawalFormUST payoutMethod='UST'/> }

        </View>
      </View>
    )
  }

  const RightComponent = () => {
    const withdrawalData = UserData.transactions?.filter(t => t.type == 'WITHDRAWAL') || []
    const sortedWithdrawalData = withdrawalData.sort((a, b) => moment(b.date, 'YYYY-MM-DD').unix() - moment(a.date, 'YYYY-MM-DD').unix())

    // const statusDict = {
    //   PENDING: 'Pending...',
    //   COMPLETED: 'Completed',
    // }

    const tableData = sortedWithdrawalData.map(dep => {
      return [
        moment(dep.date, 'YYYY-MM-DD').format('DD MMM YYYY'),
        `${formatUSD(dep.amount, true)} UST`, //,
        // statusDict[dep.status] || '-',
      ]
    })

    return (
      <View style={styles.leftWrapper}>
        <Text gutterBottom style={styles.textHeader}>Previous withdrawals</Text>
        <Table head={['Date', 'Amount']} rows={tableData} noDataCaption="You haven't made any withdrawals yet."/>
      </View>
    )
  }

  return (
    <Page>
      <SEO title='Withdraw'/>
      <WhiteGreyLayout
        leftComponent={LeftComponent}
        rightComponent={RightComponent}
      />
    </Page>
  )
}


const useStyles = makeStyles({
  topWrapper: {
    marginTop: theme.spacing(4),
  },
  leftWrapper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  depositInfoWrapper: {
    paddingBottom: theme.spacing(4),
    alignSelf: 'stretch',
    wordBreak: 'break-word',
  },
  head: {
    marginBottom: theme.spacing(1),
  },
  spaced: {
    marginTop: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  textHeader: {
    fontSize: 12,
    fontWeight: 600,
    // marginBottom: theme.spacing(2),
  },
  textHeaderSpaced: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(4),
  },
})

export default WithdrawPage
