import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import { Page, View, Text, analytics, Input, Button } from 'lib'
import theme from '_appSetup/Theme'
import { Grid, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tabs, Tab } from '@material-ui/core'
import firebase from 'gatsby-plugin-firebase'
import { fetchUserData } from 'actions/User'
import WAValidator from 'multicoin-address-validator'
import { TwoFADialog } from '../profile/_dialogs'

import { useUserData, formatUSD } from '../dashboard/_utils'

import { validateTerraAddress } from '../withdrawLuna/_withdrawalFormLuna'

import '_appSetup/Global.css'

const MINIMUM_TRANSACTION_AMOUNT = 0.5

const getRequestStatusText = (status) => {
  const options = {
    SUCCESS: { title: 'Withdrawal request submitted', text: `Your request has been submitted. `, button: 'Sent!' },
    PENDING: { title: 'Sending your request...', button: 'Please wait' },
    ERROR: { title: 'Error', text: `We encountered an error. Please refresh the page and try again. If the error persists, please contact our support.`, button: 'Error' },
    NONE: { title: 'Confirm withdrawal', button: 'Confirm withdrawal' },
  }
  return options[status]
}


const calculateAvailableToWithdraw = ({ UserData }) => {
  return { availableToWithdraw: UserData?.balance || 0, onHold: 0 }
}

const TransferDetails = (props) => {
  const styles = useStyles(props)
  const [amount, setAmount] = useState('')
  const [withdrawalAddress, setWithdrawalAddress] = useState('')
  const [memo, setMemo] = useState('')

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [twoFADialogOpen, setTwoFADialogOpen] = useState(false)
  const [withdrawalRequestStatus, setWithdrawalRequestStatus] = useState('NONE')

  const [valid, setValid] = useState({
    amount: true,
    withdrawalAddress: true,
  })

  const { UserData } = useUserData()

  const { availableToWithdraw, onHold } = calculateAvailableToWithdraw({ UserData, payoutMethod: props.payoutMethod })

  useEffect(() => {
    setAmount(formatUSD(availableToWithdraw, true))
  }, [availableToWithdraw])


  const validate = (name, value) => {
    switch (name) {
      case 'amount':
        setValid({ ...valid, amount: value > 0 && value <= availableToWithdraw && value >= MINIMUM_TRANSACTION_AMOUNT })
        break
      case 'withdrawalAddress':
        setValid({ ...valid, withdrawalAddress: value.slice(0, 5) == 'terra' && value.length == 44 && validateTerraAddress(value) })
        break
      default:
        break
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    const processedValue = value

    switch (name) {
      case 'amount':
        setAmount(processedValue)
        break
      case 'withdrawalAddress':
        setWithdrawalAddress(processedValue)
        break
      case 'memo':
        setMemo(processedValue)
        break
    }

    validate(name, processedValue)
  }

  const formIsValid = valid.amount &&
    amount > 0 && valid.withdrawalAddress && withdrawalAddress.length

  const sendRequest = (attemptId) => {
    setTwoFADialogOpen(false)
    setWithdrawalRequestStatus('PENDING')
    const withdrawalFunc = firebase.functions().httpsCallable('requestWithdrawalUST')

    const handleWithdrawalError = (e) => {
      setWithdrawalRequestStatus('ERROR')
    }

    const withdrawalArgs = { amount, withdrawalAddress, payoutMethod: props.payoutMethod, attemptId }

    withdrawalArgs.withdrawalAddress = withdrawalAddress
    withdrawalArgs.memo = memo

    withdrawalFunc(withdrawalArgs)
      .then(result => {
        if (result.data?.result == 'SUCCESS') {
          // success
          setWithdrawalRequestStatus('SUCCESS')
          fetchUserData({})
        } else {
          handleWithdrawalError()
        }
      }).catch(handleWithdrawalError)
  }

  const verify2FAIfNeeded = () => {
    setTwoFADialogOpen(true)
  }

  const modalText = {
    text: `Are you sure that you would like to withdraw ${formatUSD(amount, true)} UST and that you used the correct withdrawal address + optional memo?`,
    ...getRequestStatusText(withdrawalRequestStatus),
  }

  const withdrawalFee = {
    wire: 'Fee: $25',
    ach: 'Fee: $0',
    USDC: 'Fee: $0',
    UST: 'Fee: $0',
  }

  let helperText = ''

  if (amount > 0 && amount < MINIMUM_TRANSACTION_AMOUNT) {
    helperText = `The minimum amount you can withdraw is ${formatUSD(MINIMUM_TRANSACTION_AMOUNT)}`
  } else {
    helperText += `Available to withdraw: ${formatUSD(availableToWithdraw, true)} UST. `
    if (onHold > 0) helperText += `On hold: {formatUSD(onHold)} UST. `
    helperText += `${withdrawalFee[props.payoutMethod]}.`
  }

  return (
    <View style={styles.topWrapper}>
      <Grid container spacing={2} justify='flex-start'>

        <Grid item xs={12}>
          <Text gutterBottom>Withdraw UST over the Terra network. Read more about how it works in our <a href='https://stablegains.zendesk.com/hc/en-us/articles/6034101731089-How-to-withdraw-UST' target='_blank'>learning center</a>.</Text>
        </Grid>

        {UserData.EMERGENCY_MODE && (
          <Grid item xs={12}>
            <Text variant='subtitle2' style={styles.opaque}>Please note: the conditions of the Terra network can affect our technical ability to process UST withdrawals. We're posting updates on <a target='_blank' href='https://twitter.com/stablegains'>our Twitter account</a>.</Text>
          </Grid>
        )}

        <Grid item xs={12}>
          <Input
            value={withdrawalAddress}
            onChange={handleChange}
            name='withdrawalAddress'
            size='small'
            label={`Your UST address (Terra network only)`}
            required
            fullWidth
            autoFocus
            error={!valid.withdrawalAddress}
            helperText={valid.withdrawalAddress ? `Terra network only. Do not provide addresses on other networks, including over Ethereum/ERC20.` : 'Enter an address in the valid Terra network format.'}
          />
        </Grid>


        <Grid item xs={12}>
          <Input
            value={memo}
            onChange={handleChange}
            name='memo'
            size='small'
            label={`Memo (optional)`}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Input
            value={amount}
            onChange={handleChange}
            name='amount'
            size='small'
            type='number'
            label={'Withdrawal amount'}
            InputProps={{
              startAdornment: <InputAdornment position='start'>UST</InputAdornment>,
              onBlur: () => handleChange({ target: { name: 'amount', value: Math.round(amount * 100) / 100 } }),
            }}
            required
            error={!valid.amount}
            fullWidth
            disabled
            helperText={helperText}
          />
        </Grid>
      </Grid>

      <Button
        disabled={!formIsValid}
        style={styles.submitButton}
        variant='contained'
        size='large'
        color='secondary'
        text={'Request withdrawal'}
        onClick={() => {
          analytics.track('Withdrawal Request', {
            method: 'UST',
          })
          setConfirmationDialogOpen(true)
        } }/>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {modalText.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {modalText.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={verify2FAIfNeeded} variant='contained' color='primary' disabled={withdrawalRequestStatus !== 'NONE'}>
            {modalText.button}
          </Button>
          <Button onClick={() => setConfirmationDialogOpen(false)} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <TwoFADialog
        open={twoFADialogOpen}
        closeDialog={() => setTwoFADialogOpen(false)}
        onConfirm={(attemptId) => sendRequest(attemptId)}
        requireEmailAuthentication
      />
    </View>
  )
}


const useStyles = makeStyles({
  topWrapper: {
    marginTop: theme.spacing(4),
  },
  leftWrapper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  depositInfoWrapper: {
    paddingBottom: theme.spacing(4),
    alignSelf: 'stretch',
    wordBreak: 'break-word',
  },
  head: {
    marginBottom: theme.spacing(1),
  },
  subheader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    opacity: 0.5,
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    borderTop: '1px solid #00000016',
    paddingTop: theme.spacing(4),
  },
  spaced: {
    marginTop: theme.spacing(4),
  },
  submitButton: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  textHeader: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  checkboxLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  confirm: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  wireInstructionsWrapper: {
    marginBottom: theme.spacing(4),
  },
  opaque: {
    opacity: 0.5,
  },
})

export default TransferDetails
